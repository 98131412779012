import React, { useState, useEffect } from "react";
import BitcoinMap from "./BitcoinMap";
import { BrowserRouter, Route } from "react-router-dom";

function App() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <main>
      <section>
        <div className="mapContainer">
          <BrowserRouter>
            <Route path="/">
              <BitcoinMap modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </Route>
          </BrowserRouter>
        </div>
      </section>
    </main>
  );
}

export default App;
