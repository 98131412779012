import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polygon,
} from "@react-google-maps/api";
import logo from "./btclogo.png";
import Legal from "./components/Tabs/Legal";
import News from "./components/Tabs/News";
import Other from "./components/Tabs/Other";
import Overview from "./components/Tabs/Overview";
import Statistics from "./components/Tabs/Statistics";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { NavLink } from "react-router-dom";
import { Tab } from "@material-ui/core";
import { BrowserRouter, Route } from "react-router-dom";
import Search from "../src/components/Search";

const BitcoinMap = ({ modalOpen, setModalOpen }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const allTabs = ["Overview", "Legal", "News", "Statistics", "Other"];
  const [tabs, setTabs] = useState(allTabs);
  const [currentTab, setCurrentTab] = useState("Overview");
  const filterTabs = (tab) => {
    setCurrentTab(tab);
  };

  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  const images = importAll(
    require.context("./flagsoftheworld", false, /\.(png|jpe?g|svg|gif)$/)
  );

  // console.log(`the images are ${JSON.stringify(images)}`);

  const newLocations = [];

  const [countryData, setCountryData] = useState(newLocations);

  const btcStandardOptions = {
    fillColor: "orange",
    fillOpacity: 0.5,
  };

  const btcFriendlyOptions = {
    fillColor: "green",
    fillOpacity: 0.5,
  };

  const btcHostileOptions = {
    fillColor: "red",
    fillOpacity: 0.5,
  };

  const initialiseData = (country) => {
    if (country.properties.name == "El Salvador") {
      country.status = "btcStandard";
      country.description =
        "El Salvador is set to become the first nation in the world to adopt the Bitcoin Standard by making bitcoin legal tender. President Nayib Bukele has changed his Twitter DP to laser eyes. He recently tweeted: 'No capital gains tax for Bitcoin, since it will be a legal currency. Immediate permanent residence for crypto entrepreneurs.'\n\n The proposed bitcoin bill states: 'Central banks are increasingly taking actions that may cause harm to the economic stability of El Salvador ... in order to mitigate the negative impact from central banks, it becomes necessary to authorize the circulation of a digital currency with a supply that cannot be controlled by any central bank and is only altered in accord with objective and calculable criteria.'";
    } else if (country.properties.name == "Paraguay") {
      country.status = "btcFriendly";
      country.description =
        "Paraguay congressman Carlitos Rejala posted a photo of himself on Twitter with laser eyes and stated: 'our country needs to advance hand in hand with the new generation. The moment has come, our moment. This week we start with an important project to innovate Paraguay in front of the world! The real one to the moon #btc'";
    } else if (country.properties.name == "Brazil") {
      country.status = "btcFriendly";
      country.description =
        "Two Brazliian Congressmen have uploaded photos of themselves on Twitter with bitcoin laser eyes: Fabio Ostermann and Gilson Marques";
    } else if (country.properties.name == "Columbia") {
      country.status = "btcFriendly";
      country.description = "Columbia description";
    } else if (country.properties.name == "Panama") {
      country.status = "btcFriendly";
      country.description =
        "Gabriel Silva, Congressman in The National Assembly of Panama, commenting on El Salvador's adoption of the Bitcoin Standard: 'This is important. And Panama cannot be left behind. If we want to be a true technology and entrepreneurship hub, we have to support cryptocurrencies. We will be preparing a proposal to present at the Assembly. If you are interested in building it, you can contact me.'";
    } else if (country.properties.name == "Argentina") {
      country.status = "btcFriendly";
      country.description =
        "Francisco Sanchez, Member of the Chamber of Deputies of Argentina, changed his Twitter DP to laser eyes.";
    } else if (country.properties.name == "Mexico") {
      country.status = "btcFriendly";
      country.description =
        "Eduardo Murat, a member of Mexico's legislative branch, has stated he 'will be promoting and proposing a legal framework for crypto coins in Mexico's lower house.' Additionally, Indira Kempis, a member of the Senate, has changed her Twitter DP to laser eyes.";
    } else if (country.properties.name == "China") {
      country.status = "btcHostile";
      country.description = "Not friendly to bitcoin miners";
    } else {
      country.status = "dirtyFiatNation";
      country.description =
        "Dirty fiat nations are not welcome for bitcoiners. They worship at the alter of Central Banks. They are incredibly bad for the environment. Innovate elsewhere.";
    }
  };

  const fetchData = async () => {
    const url = `https://raw.githubusercontent.com/johan/world.geo.json/master/countries.geo.json`;
    const dataResponse = await fetch(url);
    const dataJson = await dataResponse.json();
    const theCountry = dataJson["features"];
    let newArr = [];

    for (let i = 0; i < theCountry.length; i++) {
      if (
        theCountry[i]["geometry"]["type"] == "MultiPolygon" &&
        theCountry[i]["properties"]["name"] != "Antarctica"
      ) {
        for (
          let j = 0;
          j < theCountry[i]["geometry"]["coordinates"].length;
          j++
        ) {
          const arrArrCoordMulti =
            theCountry[i]["geometry"]["coordinates"][j][0];
          const arrObjPathMulti = arrArrCoordMulti.map(([lng, lat]) => ({
            lng,
            lat,
          }));
          theCountry[i]["geometry"]["coordinates"][j][0] = arrObjPathMulti;
          // console.log(`multicoord is ${arrArrCoordMulti}`);
          const freshArr = {
            type: "Feature",
            id: theCountry[i]["id"],
            properties: { ...theCountry[i]["properties"] },
            geometry: { type: "Polygon" },
            // status: "SHITHEAD test",
          };
          freshArr["geometry"]["coordinates"] = [
            theCountry[i]["geometry"]["coordinates"][j][0],
          ];
          // initialise data
          initialiseData(freshArr);
          newArr.push(freshArr);
          // console.log(`freshArr is ${JSON.stringify(freshArr)}`);
        }
      }

      const countryName = theCountry[i].properties.name;
      const arrArrCoord = theCountry[i]["geometry"]["coordinates"][0];
      const arrObjPath = arrArrCoord.map(([lng, lat]) => ({ lng, lat }));
      theCountry[i]["geometry"]["coordinates"][0] = arrObjPath;

      // Initialise data by adding additional data to relevant countries (further info, descriptions, status, etc)
      initialiseData(theCountry[i]);

      newArr.push({ ...theCountry[i] });
    }

    setCountryData([...newArr]);
    // console.log(`country data is ${JSON.stringify(countryData)}`);
    // countryData.map((country) => console.log(country.properties.name));
    // LOOKS LIKE ALL POLYGONS WORK CORRECTLY EXCEPT MAYBE ANTARCTICA
  };

  //Fetch the country geo data
  useEffect(() => {
    fetchData();
  }, []);

  const onSelect = (item) => {
    setSelected(item);
    // console.log(`HELLO the selected item is ${JSON.stringify(item)}`);
  };

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 13.7942,
    lng: -88.8965,
  };

  const darkMapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#212121",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#212121",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#181818",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1b1b1b",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2c2c2c",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8a8a8a",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#373737",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#3c3c3c",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [
        {
          color: "#4e4e4e",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#3d3d3d",
        },
      ],
    },
  ];

  return (
    <>
      <LoadScript googleMapsApiKey="AIzaSyAmtTVSN0hQmOIc-qmHMQiBH03SXG3A8kk">
        {selected != [] && modalOpen ? (
          <SwipeableDrawer
            open={modalOpen}
            onOpen={() => setModalOpen(true)}
            onClose={() => {
              setModalOpen(false);
              setCurrentTab("Overview");
            }}
            anchor={window.screen.availWidth < 1000 ? "bottom" : "left"}
          >
            <div className="modalWrapper">
              <div
                className="exitModal"
                onClick={() => {
                  setModalOpen(false);
                  setCurrentTab("Overview");
                }}
              >
                <p className="exitCross">X</p>
              </div>
              {window.screen.availWidth < 1000 ? (
                <div className="horizontalBarContainer">
                  <div className="horizontalBar"></div>
                </div>
              ) : null}
              <div>
                <img
                  className="countryImg"
                  src={
                    images[`Flag of ${selected.properties.name}.gif`]["default"]
                  }
                />
              </div>
              <div className="country">
                <h3>{selected.properties.name}</h3>
                <h4>
                  Bitcoin Status:{" "}
                  {selected.status == "btcStandard"
                    ? "Bitcoin Standard"
                    : selected.status == "btcFriendly"
                    ? "Bitcoin Friendly"
                    : selected.status == "btcHostile"
                    ? "Bitcoin Hostile"
                    : "Dirty Fiat Nation (Environmentally Destructive)"}
                </h4>
              </div>
              <div className="optionsContainer">
                {tabs.map((tab) => {
                  return (
                    <NavLink to={`/${tab}`}>
                      <div className={tab} onClick={() => filterTabs(tab)}>
                        {tab}
                      </div>
                    </NavLink>
                  );
                })}
              </div>

              <Route exact path="/News">
                <Search selected={selected} />
              </Route>

              {/* {currentTab === "Overview" ? (
                <Overview selected={selected} />
              ) : currentTab === "Legal" ? (
                <Legal selected={selected} />
              ) : currentTab === "News" ? (
                <News selected={selected} />
              ) : currentTab === "Statistics" ? (
                <Statistics selected={selected} />
              ) : currentTab === "Other" ? (
                <Other selected={selected} />
              ) : null} */}
            </div>
          </SwipeableDrawer>
        ) : null}

        <GoogleMap
          zoom={2}
          mapContainerStyle={mapStyles}
          center={defaultCenter}
          options={{ gestureHandling: "greedy", styles: darkMapStyle }}
        >
          {countryData.map((item, index) => {
            return (
              <div>
                <Polygon
                  paths={item["geometry"]["coordinates"][0]}
                  options={
                    item.status == "btcStandard"
                      ? btcStandardOptions
                      : item.status == "btcFriendly"
                      ? btcFriendlyOptions
                      : item.properties.name == "China"
                      ? btcHostileOptions
                      : null
                  }
                  // ref={(ref) => {
                  //   this[index] = ref;
                  // }}
                  onClick={() => {
                    onSelect(item);
                    setModalOpen(true);
                    // console.log(`this.ref is ${this.ref}`);
                  }}
                  // onMouseOver={() => (this.options = { btcHostileOptions })}
                  // onMouseOver={(e) => console.log(e.target)}

                  // onMouseOver={() => console.log("HELLO")}
                />

                <Marker
                  key={index}
                  position={
                    item.status === "btcStandard"
                      ? item["geometry"]["coordinates"][0][0]
                      : null
                  }
                  onClick={() => {
                    onSelect(item);
                    setModalOpen(true);
                  }}
                  icon={{
                    url: logo,
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    height: "3px",
                    width: "50px",
                  }}
                />
              </div>
            );
          })}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default BitcoinMap;
