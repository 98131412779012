import React, { useState, useEffect } from "react";
import axios from "axios";
import Tweet from "./Tweet";
import "./Search.css";

function Search({ selected }) {
  const [data, setData] = useState({
    tweets: [],
    searchTerm: `(bitcoin OR btc) ${selected.properties.name} `,
  });

  const getData = () => {
    // e.preventDefault();
    axios
      .get(`/api/News?search_term=${data.searchTerm}`)
      .then((response) => setData({ tweets: response.data }))
      .catch((error) => console.log(`Something is wrong: ${error}`));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="tweet-card">
        {/* <div onClick={getData}>DISPLAY TWEETS!</div> */}
        {data.tweets.length != 0
          ? data.tweets.statuses.map((tweet) => {
              return <Tweet key={tweet.id} tweet={tweet} />;
            })
          : null}
      </div>
    </>
  );
}

export default Search;
