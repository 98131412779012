import React from "react";
import "./Tweet.css";
import { TwitterTweetEmbed } from "react-twitter-embed";

const Tweet = ({ tweet }) => {
  // const date = new Date(tweet.created_at);

  // const options = {
  //   hour: "numeric",
  //   minute: "numeric",
  // };

  // const newDateFormat = Intl.DateTimeFormat("en-US").format(date);
  // const newTimeFormat = Intl.DateTimeFormat("en-US", options).format(date);
  // const tweetURL = `https://twitter.com/${tweet.user.id_str}/status/${tweet.id_str}`;

  // return (
  //   <a href={tweetURL} target="_blank" onClick={() => console.log(tweet)}>
  //     <div className="card">
  //       <div>
  //         <img
  //           className="profile-image"
  //           src={tweet.user.profile_image_url}
  //           alt="user-image"
  //         />
  //       </div>
  //       <div className="tweet-body">
  //         <h2 className="user-name">{tweet.user.name}</h2>
  //         <p className="user-screen-name">@{tweet.user.screen_name}</p>
  //         <p className="tweet-time-stamp">
  //           {newDateFormat} - {newTimeFormat}
  //         </p>
  //         <p className="tweet-text">{tweet.full_text}</p>
  //         <div className="tweet-stats">
  //           <p>
  //             <i className="fas fa-retweet"></i> {tweet.retweet_count}
  //           </p>
  //           <p>
  //             <i className="fas fa-heart"></i> {tweet.favorite_count}
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   </a>
  // );

  const options = {
    cards: "hidden",
    align: "center",
    width: "550",
    conversation: "none",
  };

  return <TwitterTweetEmbed options={options} tweetId={tweet.id_str} />;
};

export default Tweet;
